import Vue from "vue";
import Vuex from "vuex";
import browser from "browser-tool";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    someData: null,
    page_name: null,
    windowInfo: {
      screenWidth: 0,
      screenHeight: 0,
    },
    mobile_swiperIndex: 0, // 移动端首页当前一屏展示的位置
  },
  mutations: {
    updateSomeData(state, payload) {
      state.someData = payload;
    },
    updatePageName(state, payload) {
      state.page_name = payload;
    },
    updareMobileSwiperIndex(state, payload) {
      state.mobile_swiperIndex = payload;
    },
    updateWindowInfo(state, payload) {
      state.windowInfo = payload;
    },
  },
  actions: {
    updateSomeData({ commit }, payload) {
      commit("updateSomeData", payload);
    },
    updatePageName({ commit }, payload) {
      commit("updatePageName", payload);
    },
    updateWindowInfoaction({ commit }, plyload) {
      let screenWidth = 0;
      let screenHeight = 0;
      screenWidth = document.body.clientWidth;
      setTimeout(() => {
        screenHeight = document.body.clientHeight;
      }, 300);

      window.onresize = () => {
        return (() => {
          screenWidth = document.body.clientWidth;
        })();
      };

      commit("updateWindowInfo", {
        screenWidth,
        screenHeight,
        ...browser(),
      });
    },
  },
  getters: {
    someData: (state) => state.someData,
    page_name: (state) => state.page_name,
  },
});
