import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/base.less";
import "animate.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "@/util/axios";
import moment from "moment";
import "moment/locale/zh-cn";
import "@/text/text.less";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
moment.locale("zh-cn");

// import 'vue-fullpage.js/dist/style.css'
// import VueFullpage from '../node_modules/vue-fullpage.js/src/index'
// Vue.use(VueFullpage)
Vue.config.productionTip = false;
import "@/assets/js/rem.js";
import {
  VueJsonp
} from "vue-jsonp";
Vue.prototype.$http = axios;

Vue.use(VueJsonp);
Vue.use(ElementUI);
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})




// 获取当前屏幕宽度
store.dispatch('updateWindowInfoaction')
// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.path) {
    _hmt.push(['_trackPageview', to.fullPath]);
  }
  if (store.state.windowInfo.screenWidth < 1025) {
    // 跳转移动端 最大兼容ipadpro
    if (to.meta.belong == "mobile") return next();
    // 如果监测到当前的链接的pc的链接就跳转H5首页
    if (to.path.indexOf("mobile") == -1) {
      next("/mobile/index");
    }
  } else {
    // 跳转pc端
    if (to.meta.belong == "pc") return next();
    // 如果监测到当前链接的H5就跳转pc首页
    if (to.path.indexOf("mobile") > -1) {
      next("/");
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");