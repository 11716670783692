<template>
  <div class="footer">
    <p
      :class="
        someData == '1' && page_name == 'index'
          ? 'zs color_fff'
          : 'zs color_000'
      "
    >
      {{ info.copyright }}
    </p>
    <p>
      <a
        :class="
          someData == '1' && page_name == 'index'
            ? 'zs color_fff'
            : 'zs color_000'
        "
        style="text-decoration: none"
        href="https://beian.miit.gov.cn"
        >Powered by NineTon Technology Co. Ltd.渝ICP备13005440号-1</a
      >
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      info: {},
    };
  },
  mounted() {
    this.getinfo();
  },
  computed: {
    ...mapGetters(["someData"]),
    ...mapGetters(["page_name"]),
  },
  methods: {
    async getinfo() {
      const [err, res] = await this.$http.get("info");
      if (err) {
        return;
      }
      this.info = res;
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  box-sizing: border-box;
  width: 100%;
  padding: 14px 0 14px 148px;
  //   background: #fff;
  position: absolute;
  z-index: 99999;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(151, 151, 151, 0.1);
  .zs {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    opacity: 0.4;
  }
  .color_000 {
    color: #000;
  }
  .color_fff {
    color: #fff;
  }
}
</style>
