<template>
  <div>
    <!-- 顶部 -->
    <div class="header" v-if="currentRoute.path !== '/video'">
      <img
        src="@/assets/img/logo.png"
        class="logo"
        v-if="currentRoute.path === '/'"
      />
      <img src="@/assets/img/logo_000.png" class="logo" v-else />
      <div class="content space-between">
        <nav class="right">
          <div class="space-between ac">
            <ul
              :class="
                currentRoute.path === '/' && someData == '1'
                  ? 'color_fff flex'
                  : 'color_000 flex'
              "
            >
              <li
                v-for="(item, index) in nav"
                :key="item.id"
                @click="navClick(item)"
                @mouseenter="openSubMenu(index)"
                @mouseleave="closeSubMenu"
                :class="
                  currentRoute.path == item.path ? 'active' : 'opacity_text'
                "
              >
                <span>{{ item.text }}</span>
                <ul
                  v-show="activeIndex === index"
                  :class="
                    currentRoute.path === '/' && someData == '1'
                      ? 'color_fff sub-menu'
                      : 'color_000 sub-menu'
                  "
                  :style="mouseenterClass"
                >
                  <li
                    v-for="(subItem, subIndex) in item.children"
                    :key="subIndex"
                    :class="
                      currentRoute.path == item.path && someData == '1'
                        ? 'active sub-menu-item'
                        : 'opacity_text sub-menu-item'
                    "
                    @click.stop="navClickItem(item, subItem.index)"
                  >
                    {{ subItem.text }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="lag">
        <!-- changeLag -->
        <div class="changlag">
          <span
            :class="
              currentRoute.path === '/' && someData == '1'
                ? 'color_fff'
                : 'color_000'
            "
          >
            <span
              @click="goEnglish"
              :class="lag_flag == 'zh' ? 'active' : 'span_opacity'"
              >海外版</span
            >
          </span>
        </div>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      currentRoute: {}, // 当前的路由信息
      nav: [
        {
          id: 1,
          text: "首页",
          path: "/",
        },
        {
          id: 4,
          text: "业务",
          path: "/apps",
          children: [
            {
              text: "移动app",
              path: "/about",
              index: 1,
            },
            {
              text: "合作",
              path: "/about",
              index: 2,
            },
            {
              text: "SDK广告",
              path: "/about",
              index: 3,
            },
          ],
        },
        {
          id: 2,
          text: "简介",
          path: "/about",
          children: [
            {
              text: "价值观",
              path: "/about",
              index: 1,
            },
            {
              text: "管理团队",
              path: "/about",
              index: 2,
            },
            {
              text: "九吨大事记",
              path: "/about",
              index: 3,
            },
          ],
        },
        {
          id: 3,
          text: "文化",
          path: "/culture",
          children: [
            {
              text: "九吨生活",
              path: "/culture",
              index: 1,
            },
            {
              text: "九吨新闻",
              path: "/culture",
              index: 2,
            },
          ],
        },
        {
          id: 5,
          text: "招贤纳士",
          path: "/join",
          children: [
            {
              text: "人才招聘",
              path: "/about",
              index: 1,
            },
            {
              text: "九吨福利",
              path: "/about",
              index: 2,
            },
            {
              text: "联系方式",
              path: "/about",
              index: 3,
            },
          ],
        },
      ],
      activeIndex: -1,
      lag_flag: "zh",
      mouseenterClass: "",
    };
  },
  watch: {
    $route: {
      handler: function (val) {
        this.currentRoute = val;
        this.updatePageName(val.name);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions(["updatePageName"]),
    navClick(item) {
      /*   if (
        this.currentRoute.path == item.path &&
        this.$route.query.page == index
      )
        return; */
      this.$router.push(item.path);
    },
    navClickItem(item, index) {
      if (index) {
        this.$router.push(item.path + "?page=" + index);
      } else {
        this.$router.push(item.path);
      }
    },
    openSubMenu(index) {
      this.activeIndex = index;
      this.mouseenterClass = "";
    },
    closeSubMenu() {
      this.activeIndex = -1;
      this.mouseenterClass = "";
    },
    goEnglish() {
      window.open("http://www.nineton.sg/pc.html#/Home", "_blank");
    },
  },
  updated() {
    if (this.activeIndex > -1) {
      const subMenuItems = this.$el.querySelectorAll(".sub-menu-item");
      subMenuItems.forEach((item, index) => {
        item.style.animation = `slide-in 0.3s ease-out ${
          index * 0.1
        }s forwards`;
      });
    }
  },
  computed: {
    ...mapGetters(["page_name"]),
    ...mapGetters(["someData"]),
  },
};
</script>

<style scoped lang="less">
.color_fff {
  color: #fff;
}
.color_000 {
  color: #000;
}
.logo {
  width: 178px;
  height: 31px;
}
.header {
  // padding: 0 80px 0 359px;
  z-index: 9999;
  // height: 160px;
  box-sizing: border-box;
  position: fixed;
  top: 56px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 140px 0 60px;
  .content {
    position: relative;
    margin: 0 auto;

    .logo {
      width: 151px;
      height: 57px;
    }

    .login {
      cursor: pointer;
      width: 88px;
      height: 40px;
      background: #f4f1de;
      border-radius: 10px;
      border: 2px solid #7c2e11;
      color: #7c2d10;
      font-size: 20px;
      position: absolute;
      right: -192px;
    }

    .right {
      li {
        position: relative;
        margin-right: 92px;
        font-size: 18px;
        cursor: pointer;
        span {
          position: relative;
          z-index: 99;
        }
      }
      .active {
        position: relative;
        z-index: 1;
        &::after {
          content: "";
          position: absolute;
          width: 42px;
          height: 13px;
          background-size: cover;
          bottom: -4px;
          left: 50%;
          transform: translate(-50%);
        }
      }
      .sub-menu {
        position: absolute;
        top: 100%;
        left: 0;
        list-style: none;
        padding: 0;
        margin: 0;
        &:first-of-type {
          padding: 5px 0;
        }
      }

      .sub-menu li {
        cursor: pointer;
        z-index: 9999;
        font-size: 14px;
        width: 100px;
        padding: 5px 0;
      }
      .opacity_text {
        opacity: 0.5;
      }
    }
  }
  .changlag {
    width: 100px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    .span_opacity {
      opacity: 0.54;
    }
    .active {
      font-size: 16px;
      opacity: 1;
      cursor: pointer;
    }
  }
}
.sub-menu-item {
  animation-name: slideUp;
  animation-duration: 500ms;
  animation-timing-function: ease;
  &:hover {
    // color: #fff;
    opacity: 1 !important;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
