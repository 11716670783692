var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentRoute.path !== '/video')?_c('div',{staticClass:"header"},[(_vm.currentRoute.path === '/')?_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo.png")}}):_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/img/logo_000.png")}}),_c('div',{staticClass:"content space-between"},[_c('nav',{staticClass:"right"},[_c('div',{staticClass:"space-between ac"},[_c('ul',{class:_vm.currentRoute.path === '/' && _vm.someData == '1'
                ? 'color_fff flex'
                : 'color_000 flex'},_vm._l((_vm.nav),function(item,index){return _c('li',{key:item.id,class:_vm.currentRoute.path == item.path ? 'active' : 'opacity_text',on:{"click":function($event){return _vm.navClick(item)},"mouseenter":function($event){return _vm.openSubMenu(index)},"mouseleave":_vm.closeSubMenu}},[_c('span',[_vm._v(_vm._s(item.text))]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndex === index),expression:"activeIndex === index"}],class:_vm.currentRoute.path === '/' && _vm.someData == '1'
                    ? 'color_fff sub-menu'
                    : 'color_000 sub-menu',style:(_vm.mouseenterClass)},_vm._l((item.children),function(subItem,subIndex){return _c('li',{key:subIndex,class:_vm.currentRoute.path == item.path && _vm.someData == '1'
                      ? 'active sub-menu-item'
                      : 'opacity_text sub-menu-item',on:{"click":function($event){$event.stopPropagation();return _vm.navClickItem(item, subItem.index)}}},[_vm._v(" "+_vm._s(subItem.text)+" ")])}),0)])}),0)])])]),_c('div',{staticClass:"lag"},[_c('div',{staticClass:"changlag"},[_c('span',{class:_vm.currentRoute.path === '/' && _vm.someData == '1'
              ? 'color_fff'
              : 'color_000'},[_c('span',{class:_vm.lag_flag == 'zh' ? 'active' : 'span_opacity',on:{"click":_vm.goEnglish}},[_vm._v("海外版")])])])])]):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }