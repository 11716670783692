<template>
  <div class="header-wrapper">
    <div class="header-box space-between ac" :class="openFlag ? 'active' : ''">
      <img :src="headerImg.logo" class="logo" alt="">
      <img :src="openFlag ? headerImg.menuClose : headerImg.menu" class="menu-icon" alt="" @click="openFlag = !openFlag">
    </div>
    <ul class="menu-box" v-show="openFlag">
      <li class="menu-item ac" v-for="(item, index) in list" :key="index" @click="meunClick(item)">
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
  data() {
    return {
      openFlag: false,
      list: [
        {
          title: "首页",
          index: 0,
          path: "/mobile/index"
        },
        {
          title: "业务",
          index: 1,
          path: "/mobile/index"
        },
        {
          title: "简介",
          index: 2,
          path: "/mobile/index"
        },
        {
          title: "文化",
          index: 5,
          path: "/mobile/index"
        },
        // {
        //   title: "九吨生活",
        //   path: "/mobile/life"
        // },
        {
          title: "联系我们",
          path: "/mobile/contact"
        },
        {
          title: "English",
          path: "http://www.nineton.sg/mobile.html#/home"
        },
      ]
    }
  },
  computed: {
    ...mapState(['mobile_swiperIndex']),
    // 动态获取logo图标，有黑色和白色
    headerImg() {
      const path = this.$route.path
      // 只有在首屏的时候用白色logo,打开菜单以及其他页面都是用黑色
      if (this.mobile_swiperIndex == 0 && !this.openFlag && path == '/mobile/index') {
        //  白色
        return {
          logo: require('@/assets/img/logo.png'),
          menu: require('@/assets/img/mobile/1.png'),
          menuClose: require('@/assets/img/mobile/14.png'),
        }
      } else {
        // 黑色
        return {
          logo: require('@/assets/img/mobile/15.png'),
          menu: require('@/assets/img/mobile/3.png'),
          menuClose: require('@/assets/img/mobile/12.png'),
        }
      }
    }
  },
  methods: {
    ...mapMutations(['updareMobileSwiperIndex']),
    meunClick(item) {
      this.openFlag = false
      if (item.path.indexOf('http') > -1) {
        window.open(item.path)
      } else {
        const path = this.$route.path
        if (path == '/mobile/index' && item.index > -1) {
          // 更新首页模块
          this.updareMobileSwiperIndex(item.index)
        } else {
          this.$router.push(item.path)
          if (item.index > -1) {
            this.updareMobileSwiperIndex(item.index)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.header-wrapper {
  position: relative;
}

.active {
  background: #FFF;
}

.header-box {
  padding: 0 .5333rem;
  box-sizing: border-box;
  // background: rgba(0, 0, 0, 0.5);
  // background: #FFF;
  width: 10rem;
  height: 1.2267rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .logo {
    width: 2.6667rem
  }

  .menu-icon {
    width: .7067rem;
    height: .7067rem;
  }
}

.menu-box {
  width: 100%;
  height: calc(100vh - 1.2267rem);
  position: absolute;
  left: 0;
  top: 1.2267rem;
  background: #FFF;
  z-index: 9999;
  padding: 1.5467rem .6933rem 0 .6533rem;
  box-sizing: border-box;

  .menu-item {
    font-size: .3733rem;
    font-weight: bold;
    // padding-bottom: .4rem;
    // box-sizing: border-box;
    height: 1.4533rem;
    border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  }
}
</style>