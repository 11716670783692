import Vue from 'vue'
import VueRouter from 'vue-router'

import Header from '@/layout/header'
import Footer from '@/layout/footer'
import MobileLayout from '@/layout/mobile/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    components: { default: Header, namedComponentB: Footer },
    children: [
      {
        path: '/',
        name: 'index',
        meta:{
          belong:'pc'
        },
        component: () => import('../views/home/index.vue')
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    components: { default: Header, namedComponentB: Footer },
    children: [{
      path: '/about',
      name: 'about',
      meta:{
        belong:'pc'
      },
      component: () => import('../views/about/index.vue')
    },]
  },
  {
    path: '/culture',
    name: 'culture',
    components: { default: Header, namedComponentB: Footer },
    children: [{
      path: '/culture',
      name: 'culture',
      meta:{
        belong:'pc'
      },
      component: () => import('../views/culture/index.vue')
    },]
  },
  {
    path: '/join',
    name: 'join',
    components: { default: Header, namedComponentB: Footer },
    children: [{
      path: '/join',
      name: 'join',
      meta:{
        belong:'pc'
      },
      component: () => import('../views/join/index.vue')
    },]
  },
  {
    path: '/apps',
    name: 'apps',
    components: { default: Header, namedComponentB: Footer },
    children: [{
      path: '/apps',
      name: 'apps',
      meta:{
        belong:'pc'
      },
      component: () => import('../views/apps/index.vue')
    },]
  },
  {
    path: '/video',
    name: 'video',
    components: { default: Header},
    children: [{
      path: '/video',
      name: 'video',
      meta:{
        belong:'pc'
      },
      component: () => import('../views/video/index.vue')
    },]
  },
  // 手机端路由
  {
    path: '/mobile',
    name: 'mobile',
    component:MobileLayout,
    children:[
      {
        path: '/mobile/index',
        name: 'mobile_index',
        meta:{
          belong:'mobile'
        },
        component: () => import('../views/mobile/home/index.vue')
      },
      {
        path: '/mobile/life',
        name: 'mobile_life',
        meta:{
          belong:'mobile'
        },
        component: () => import('../views/mobile/life/index.vue')
      },
      {
        path: '/mobile/contact',
        name: 'mobile_contact',
        meta:{
          belong:'mobile'
        },
        component: () => import('../views/mobile/contact/index.vue')
      },
      {
        path: '/mobile/welfare',
        name: 'mobile_welfare',
        meta:{
          belong:'mobile'
        },
        component: () => import('../views/mobile/welfare/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
