<template>
  <div id="app">
    <router-view />
    <router-view
      name="namedComponentB"
      v-if="windowInfo.screenWidth > 1024"
    ></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    // 获取window信息
    ...mapState(["windowInfo"]),
  },
  async created() {
    const response = await fetch("/version.json");
    const versionData = await response.json();
    const cachedVersion = localStorage.getItem("version");

    if (versionData.version !== cachedVersion) {
      localStorage.clear(); // 或者清理你需要清理的特定数据
      localStorage.setItem("version", versionData.version);
    }
  },
};
</script>

<style lang="less" scoped>
</style>
